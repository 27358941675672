<script>
import { Input, Button } from 'ant-design-vue'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getAction } from '@/command/netTool'
export default {
  data() {
    return {
      username: '',
      password: '',
      phoneNumber: '',
      code: '',
      loginWay: false,
      loginWayCode: true,
      loginWayFindPass: true,
      getCodeMsg: '获取验证码',
      getDisabled: false,
      countdown: 60,
      timeOut: '',
      isShow: 'login-form-item',
      sysData: ['userInfos'],
    }
  },
  mounted() {
    this.sysData.forEach((e) => {
      localStorage.removeItem(e)
    })
  },
  methods: {
    ...mapActions(['Login', 'Logout', 'GetCode', 'LoginByCode', 'GetCodeFind', 'UpdatePwd']),
    renderFormItem(item) {
      return (
        <div class={this.isShow}>
          <span>{item.name}</span>
          <Input
            v-model={this[item.key]}
            type={item.key}
            placeholder={item.placeholder}
            disabled={item.disabled}
            onPressEnter={() => item.onChange()}
          />
        </div>
      )
    },
    loginSuccess(userData) {
      localStorage.setItem('userInfos', JSON.stringify(userData.user_info))
      if (localStorage.getItem('historyUrl')) {
        window.location.href = localStorage.getItem('historyUrl')
      } else {
        this.$router.replace('/')
      }
    },
    onLogin() {
      if (this.username === '' || this.password === '') {
        this.$message.error('请输入账号/密码')
        return
      }
      this.Login({
        username: this.username,
        password: this.password,
      })
        .then((result) => {
          this.loginSuccess(result)
        })
        .catch(() => this.$message.error('账号或密码错误'))
        .finally(() => {
          // state.loginBtn = false
        })
    },
    // 验证码登录按钮
    onLoginByCode() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.LoginByCode({
        phoneNumber: this.phoneNumber,
        code: this.code,
      }).then((e) => {
        if (e.access_token) {
          this.loginSuccess(e)
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    renderFormButton() {
      return (
        <div class="login-form-button" onClick={this.onLogin}>
          登录
        </div>
      )
    },
    renderFormButton2() {
      return (
        <div class="login-form-button" onClick={this.onLoginByCode}>
          登录
        </div>
      )
    },
    renderFormButton3() {
      return (
        <div class="login-form-button" onClick={this.onNext}>
          确认
        </div>
      )
    },
    // 找回手机确认
    onNext() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.UpdatePwd({
        code: this.code,
        password: this.password,
        phone: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.resetValue()
          this.isShow = 'login-form-item'
          this.$message.success('修改密码成功')
          this.loginWayFindPass = !this.loginWayFindPass
          this.loginWay = !this.loginWay
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    renderForm() {
      const form = [
        {
          name: '账号/用户名',
          key: 'username',
        },
        {
          name: '登录密码',
          key: 'password',
          onChange: () => {
            this.onLogin()
          },
        },
      ]
      const form2 = [
        {
          name: '手机号',
          key: 'phoneNumber',
        },
        {
          name: '验证码',
          key: 'code',
          placeholder: '6位验证码',
          onChange: () => {
            this.onLoginByCode()
          },
        },
      ]
      const form3 = [
        {
          name: '手机号',
          key: 'phoneNumber',
        },
        {
          name: '输入新密码',
          key: 'password',
        },
        {
          name: '验证码',
          key: 'code',
          placeholder: '6位验证码',
          onChange: () => {
            this.onNext()
          },
        },
      ]

      return (
        <div class="login-form">
          <div>欢迎来到</div>
          <div>慈溪数据接口后台</div>
          <div class="login-form-view" v-show={!this.loginWay}>
            {form.map((e) => this.renderFormItem(e))}
            {this.renderChangeLogin()}
            {this.renderFormButton()}
          </div>
          <div class="login-form-view" v-show={!this.loginWayCode}>
            {form2.map((e) => this.renderFormItem(e))}
            {this.renderLoginButton()}
            {this.renderChangeLogin2()}
            {this.renderFormButton2()}
          </div>
          <div class="login-form-view2" v-show={!this.loginWayFindPass}>
            {form3.map((e) => this.renderFormItem(e))}
            {this.renderLoginButton2()}
            {this.renderChangeLogin3()}
            {this.renderFormButton3()}
          </div>
        </div>
      )
    },
    renderLoginButton() {
      let text = this.getCodeMsg
      return (
        <div class="login-code-button">
          <Button type="ghost" onClick={this.getCode} class="login-code-button2" disabled={this.getDisabled}>
            {{ text }}
          </Button>
        </div>
      )
    },
    renderLoginButton2() {
      let text = this.getCodeMsg
      return (
        <div class="login-code-button">
          <Button type="ghost" onClick={this.getCodeFind} class="login-code-button2" disabled={this.getDisabled}>
            {{ text }}
          </Button>
        </div>
      )
    },
    // 获取找回密码验证码
    getCodeFind() {
      this.GetCodeFind({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    // 获取验证码
    getCode() {
      this.GetCode({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    setCountdown: function () {
      if (this.countdown === 0) {
        this.getCodeNow()
      } else {
        this.getCodeMsg = this.countdown + 's后重新获取'
        this.getDisabled = true
        this.countdown--
      }
    },

    getCodeNow() {
      this.getCodeMsg = '获取验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
    },
    renderChangeLogin() {
      return (
        <div class="login-change">
          <a href="#" onClick={this.loginCode}>
            验证码登录
          </a>
          <a href="#" onClick={this.loginFindCode}>
            找回密码
          </a>
        </div>
      )
    },
    // 找回密码双返回按钮
    renderChangeLogin3() {
      return (
        <div class="login-change">
          <a href="#" onClick={this.loginBackCount}>
            账号密码登录
          </a>
          <a href="#" onClick={this.loginBackCode}>
            验证码登录
          </a>
        </div>
      )
    },
    // 重置
    resetValue() {
      this.getCodeMsg = '获取验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
      this.timeOut = ''
      this.username = ''
      this.password = ''
      this.phoneNumber = ''
      this.code = ''
    },
    loginBackCode() {
      this.isShow = 'login-form-item'
      this.resetValue()
      this.loginWayFindPass = !this.loginWayFindPass
      this.loginWayCode = !this.loginWayCode
    },
    loginBackCount() {
      this.isShow = 'login-form-item'
      this.resetValue()
      this.loginWayFindPass = !this.loginWayFindPass
      this.loginWay = !this.loginWay
    },
    // 找回密码
    loginFindCode() {
      this.isShow = 'login-form-item2'
      this.resetValue()
      this.loginWay = !this.loginWay
      this.loginWayFindPass = !this.loginWayFindPass
    },
    renderChangeLogin2() {
      return (
        <div class="login-change">
          <a href="#" onClick={this.loginCode}>
            账号密码登录
          </a>
        </div>
      )
    },
    // 验证码切换登录按钮
    loginCode() {
      // this.getCodeMsg = '获取验证码'
      // this.getDisabled = false
      // this.countdown = 60
      // clearInterval(this.timeOut)
      this.resetValue()
      this.loginWay = !this.loginWay
      this.loginWayCode = !this.loginWayCode
    },
    renderBody() {
      return (
        <div class="login-body">
          <div class="img-box">
            <img src={require('./asset/1.gif')} />
          </div>
          {this.renderForm()}
        </div>
      )
    },
  },
  render() {
    return <div class="login-main">{this.renderBody()}</div>
  },
}
</script>
<style lang="less" scoped>
.login-main {
  display: flex;
  background-image: url('./asset/bk.webp');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.login-body {
  width: 1087px;
  height: 665px;
  display: flex;
  background-image: url('./asset/body-bk.webp');
  background-repeat: no-repeat;
  // background-image: url("../../assets/View/login-body.png");
  background-size: 100% 100%;
  position: relative;

  .img-box {
    position: absolute;
    top: 100px;
    left: 100px;
    width: 505px;
    height: 465px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.login-form {
  position: absolute;
  right: 50px;
  top: calc(113px);
  bottom: 35px;
  border-radius: 30px;
  width: 369px;
  padding: 33px;
  /deep/.ant-input {
    background: transparent !important;
  }
  /deep/.ant-input-affix-wrapper {
    border: none;
    background: transparent !important;
    outline: none;
    width: 95%;
    box-shadow: none;
  }

  & > :nth-child(-n + 2) {
    font-size: 27px;
    font-family: Microsoft YaHei, serif;
    font-weight: bold;
    color: #fff;
    position: relative;
    &:nth-child(2)::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: -10px;
      width: 59px;
      height: 3px;
      background: #fff;
    }
  }
}
.login-form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 218px;
  height: 40px;
  //   background: linear-gradient(-90deg, #80a5ff 0%, #2665fa 100%);
  background-color: #0047f8;
  border-radius: 27px;
  font-size: 14px;
  font-family: PingFang SC, serif;
  font-weight: bold;
  color: #ffffff;
  margin-top: 46px;
  cursor: pointer;
}
.login-form-button-back {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 109px;
  height: 40px;
  background: linear-gradient(0deg, #1ec57b 0%, #26e8ac 100%);
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFang SC, serif;
  font-weight: bold;
  color: #ffffff;
  margin-top: 46px;
  cursor: pointer;
}
.login-form-button-next {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 109px;
  height: 40px;
  background: linear-gradient(0deg, #1ec57b 0%, #26e8ac 100%);
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFang SC, serif;
  font-weight: bold;
  color: #ffffff;
  margin-top: 46px;
  cursor: pointer;
}
.login-form-item {
  &:not(&:first-child) {
    margin-top: 35px;
  }
  span {
    font-size: 13px;
    font-family: PingFang-SC-Middle, PingFang-SC;
    font-weight: 300;
    color: #fff;
  }
  input {
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    margin-top: 5px;
    padding: 0;

    color: #fff;

    &::placeholder {
      color: #fff;
    }
  }
}
.login-form-item2 {
  //&:not(&:first-child) {
  //  margin-top: 15px;
  //}
  margin-top: 7px;
  span {
    font-size: 13px;
    font-family: PingFang-SC-Middle, PingFang-SC;
    font-weight: 300;
    color: #fff;
  }
  input {
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    margin-top: 3px;
    padding: 0;

    color: #fff;
    &::placeholder {
      color: #fff;
    }
  }
}
.login-form-view {
  position: relative;
  margin-top: 40px;
}
.login-form-view2 {
  position: relative;
  margin-top: 20px;
}
.login-change {
  :nth-of-type(2) {
    float: right;
  }
}
a {
  float: left;
  display: block;
  margin-top: 13px;
  text-decoration: none;
  font-size: 13px;
  //   color: rgb(128, 128, 255);
  color: #fff;
}
.login-code-button {
  //border: 1px solid #dcdee2;
  position: absolute;
  top: 26px;
  right: 0;
  cursor: pointer;
  text-align: center;
}
.login-code-button2 {
  border: 0;
  width: 103px;
  height: 28px;
  border-radius: 3px;
  line-height: 28px;
  font-size: 13px;
  text-align: right;
  padding: 0;
  color: #fff;
}
</style>
